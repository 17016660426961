<div class="edit-gdpr-container" [class.hidden]="loadingIndicatorService.loading$ | async">
  <div id="editGdprHeader">
    <groupui-headline>{{'edit-gdpr.header' | translate}}</groupui-headline>
  </div>

  <!-- tabs to switch between the gdpr content -->
  <div id="gdprContentTabs">
    <groupui-tabs [class.disabled]="!isContentEditorDisabled" id="edit-gdpr-topics" (groupuiTabChange)="updateTopic($event.detail.tab)">
      <label>{{'edit-gdpr.description' | translate}}</label>
      <groupui-tab [class.disabled]="!isContentEditorDisabled" *ngFor="let id of editGdprTabIds" tab="{{id}}" name="{{'shell.footer.' + id | translate}}">
      </groupui-tab>
    </groupui-tabs>
  </div>
  <!-- language table - overview of the created languages, display modes and default language -->
  <div id="languageTable"  [class.disabled]="!isContentEditorDisabled">
    <app-language-table *ngIf="(content$ | async) as content" [content]="content"
      (changeContent)="performModalAction(true, $event)" (currentDefaultLanguage)="setCurrentDefaultLanguage($event)"></app-language-table>
  </div>

  <!-- editable content with the text editor and pdf upload -->
  <div id="contendEditor" [class.disabled]="isContentEditorDisabled">
    <app-content-editor [attr.tabindex]="isContentEditorDisabled ? -1 : null" [selectedContent]="selectedContent" [incommingText]="editContent" [existingFileName]="fileName" [editReason]="modalReason" (changeContent)="performModalAction(true, $event)"></app-content-editor>
  </div>
</div>

<!-- modal window - query execution of the actions  -->
<groupui-modal id="editGdprModal" close-button="false" padding="40px" disable-backdrop-close="true" disable-escape-close="true">
  <groupui-text>{{'edit-gdpr.modal-reasons.' + modalReason | translate}}</groupui-text>
  <groupui-button-group id="actionButtons" disabled="false" inverted="false" variant="button">
    <groupui-button variant='secondary' (click)="performModalAction(false)">{{'edit-gdpr.buttons.back' | translate}}</groupui-button>
    <groupui-button variant='primary' (click)="performModalAction(true)" *ngIf="modalReason !== 'deleteDefault'">{{'edit-gdpr.buttons.' + modalReason | translate}}</groupui-button>
  </groupui-button-group>
</groupui-modal>
