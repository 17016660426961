import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditGdprComponent } from './edit-gdpr.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { UiModule } from '@ui/ui.module';
import { LanguageTableComponent } from './components/language-table/language-table.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentEditorComponent } from './components/content-editor/content-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    EditGdprComponent,
    LanguageTableComponent,
    ContentEditorComponent
  ],
    imports: [
        CommonModule,
        GroupUiModule,
        ReactiveFormsModule,
        HttpClientModule,
        AngularEditorModule,
        UiModule,
        NgSelectModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient): TranslateHttpLoader => (new TranslateHttpLoader(http, './assets/i18n/')),
                deps: [HttpClient]
            }
        }),
        MatProgressSpinnerModule,
    ]
})
export class EditGdprModule { }
