<div [class.hidden]="loadingIndicatorService.loading$ | async">
  <div>
    <form [formGroup]="filterFormGroup">

      <label class="labels">{{ 'historical-readouts.filters.timerange-label' | translate}}</label>

      <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="pickerFrom" formControlName="from" [min]="minDateFrom"
          [max]="maxDateFrom" (dateChange)="onFilterData()" placeholder="DD-MM-YYYY HH:MM">
        <mat-datepicker-toggle matSuffix [for]="$any(pickerFrom)" #toggleFrom
          (keydown)="handleKeydown($event)"></mat-datepicker-toggle>
        <mat-hint>
          {{ getFromFieldHint() | translate}}
        </mat-hint>
        <ngx-mat-datetime-picker #pickerFrom></ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="pickerTo" formControlName="to" [min]="minDateTo"
          (dateChange)="onFilterData()" placeholder="DD-MM-YYYY HH:MM">
        <mat-datepicker-toggle matSuffix [for]="$any(pickerTo)" #toggleTo
          (keydown)="handleKeydown($event)"></mat-datepicker-toggle>
        <mat-hint>
          {{ getToFieldHint() | translate}}
        </mat-hint>
        <ngx-mat-datetime-picker #pickerTo></ngx-mat-datetime-picker>
      </mat-form-field>




      <div style="display: flex">
        <groupui-checkbox id="is_own" class="is_own container-border flex-center form-control" formControlName="is_own"
                          [checked]="filterFormGroup.controls.is_own.value" (groupuiChange)="onToggleShowOwnReadoutsCheckbox()"
                          (keydown.enter)="onToggleShowOwnReadoutsCheckbox()" (keydown.tab)="focusTableWithTabKey()">
          {{ 'historical-readouts.filters.show-own-readouts-label' | translate}}
        </groupui-checkbox>
        <label class="labels"> {{ 'VIN' }} </label>
        <ng-select placeholder="{{ 'historical-readouts.filters.all-placeholder' | translate}}" formControlName="vin"
          class="form-control vin" (change)="onFilterData()">
          <ng-option *ngFor="let vin of availableVin" [value]="vin">{{vin}}</ng-option>
        </ng-select>

        <label class="labels">{{ 'historical-readouts.filters.brand-code-label' | translate}}</label>
        <ng-select placeholder="{{ 'historical-readouts.filters.all-placeholder' | translate}}"
          formControlName="brand_code" class="form-control brand-code" (change)="onFilterData()">
          <ng-option *ngFor="let brandCode of availableBrandCodes"
            [value]="brandCode">{{getBrandNameByCode(brandCode)}}</ng-option>
        </ng-select>


      </div>
    </form>
  </div>

  <groupui-table columns="6" sticky="false" header="true" indicator="default" scroll-y="true" style="overflow: auto;
height: 48vh;display: flex;flex-direction: column;width: 100%;">
    <!-- table header -->
    <groupui-table-cell class="groupui-table-cell-header">{{ 'historical-readouts.table-columns.timestamp' |
      translate}}</groupui-table-cell>
    <groupui-table-cell class="groupui-table-cell-header">{{ 'historical-readouts.table-columns.brand' |
      translate}}</groupui-table-cell>
    <groupui-table-cell class="groupui-table-cell-header">{{ 'historical-readouts.table-columns.VIN' |
      translate}}</groupui-table-cell>
    <groupui-table-cell class="groupui-table-cell-header">{{ 'historical-readouts.table-columns.model-name' |
      translate}}</groupui-table-cell>
    <groupui-table-cell class="groupui-table-cell-header">{{ 'historical-readouts.table-columns.model-year' |
      translate}}</groupui-table-cell>
    <groupui-table-cell class="groupui-table-cell-header">{{ 'historical-readouts.table-columns.mileage' |
      translate}}</groupui-table-cell>

    <!-- table content -->

    <ng-template [ngIf]="pagination.page_contents !== undefined && pagination.page_contents.length > 0">
      <ng-container *ngFor="let tableRow of pagination.page_contents; let i = index" id="historical-key-data-table"
        style="overflow: auto;max-height: 200px;">
        <groupui-table-cell (click)="toggleModalWindow(true,tableRow.request_id)" [class.hover]="i=== highlightedRowId">
          {{tableRow.update_time !== null ? (tableRow.update_time + "Z" | date: 'dd-MM-YYYY HH:mm') : ""}}
        </groupui-table-cell>
        <groupui-table-cell (click)="toggleModalWindow(true,tableRow.request_id)" [class.hover]="i=== highlightedRowId">
          {{getBrandNameByCode(tableRow.brand_code)}}
        </groupui-table-cell>
        <groupui-table-cell (click)="toggleModalWindow(true,tableRow.request_id)" [class.hover]="i=== highlightedRowId">
          {{tableRow.vin}}
        </groupui-table-cell>
        <groupui-table-cell (click)="toggleModalWindow(true,tableRow.request_id)" [class.hover]="i=== highlightedRowId">
          {{tableRow.model_name}}
        </groupui-table-cell>
        <groupui-table-cell (click)="toggleModalWindow(true,tableRow.request_id)" [class.hover]="i=== highlightedRowId">
          {{tableRow.model_year}}
        </groupui-table-cell>
        <groupui-table-cell (click)="toggleModalWindow(true,tableRow.request_id)" [class.hover]="i=== highlightedRowId">
          {{tableRow.mileage}}
        </groupui-table-cell>
      </ng-container>
    </ng-template>

    <!-- no data available - unhappy path -->
    <ng-template [ngIf]="pagination.page_contents !== undefined && pagination.page_contents.length == 0">
      <groupui-table-cell>{{ 'historical-readouts.table-columns.no-data' | translate}}</groupui-table-cell>
      <groupui-table-cell></groupui-table-cell>
      <groupui-table-cell></groupui-table-cell>
      <groupui-table-cell></groupui-table-cell>
      <groupui-table-cell></groupui-table-cell>
      <groupui-table-cell></groupui-table-cell>
    </ng-template>

  </groupui-table>
  <ng-template [ngIf]="pagination.page_contents !== undefined && pagination.page_contents.length > 0">
    <groupui-pagination totalElements="{{pagination.total_count}}" pageSize="{{pagination.page_size}}"
                          (groupuiPageSizeChange)="onGroupUiPageSizeChange($event)"
                          (groupuiPageChange)="onGroupUiPageChange($event)"
                          elementsPerPageLabel="{{ 'historical-readouts.pagination-labels.elements-per-page' | translate }}"
                          visibleElementsLabel="{{ 'historical-readouts.pagination-labels.visible-elements'| translate: { displayedElements: getDisplayedElements(), elementQuantity: pagination.total_count } }}"
                          visiblePageLabel="{{ 'historical-readouts.pagination-labels.visible-page' | translate }}"
                          pageSizeOptions="{{paginationPageSizeOptions}}">
    </groupui-pagination>
  </ng-template>
</div>

<!-- modal window -->
<groupui-modal (keydown)="focusCloseButtonOnFirstTab($event)" id="keyDataModal" background="dark" close-button="true"
  disable-backdrop-close="true" max-width="unset" margin="50px 25px" padding="20px 25px"
  (onGroupuiModalClose)="focusRowAfterModalClose()">
  <br>
  <app-key-data [call]="groupServiceKey"></app-key-data>
  <!-- is needed to limit the navigation with the keyboard to the modal window when this is opened-->
  <span class="focusguard" id="focusguard" tabindex="0"></span>
</groupui-modal>
