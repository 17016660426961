import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IGdprRelevantElement } from '@utils/interfaces/gdpr/gdpr-relevant-element.interface';
import { MapService } from '@services/map/map.service';
import { EditGdprActionEnum } from '@utils/enums/edit-gdpr-action.enum';
import { ContentEditorModeEnum } from '@utils/enums/content-editor-mode.enum';
import { TranslateService } from '@ngx-translate/core';
import { GdprService } from '@services/gdpr/gdpr.service';

/**
 * This component contains a text eiditor and a possibility to upload a PDF.
 * This is used for the GDPR related content.
 */
@Component({
  selector: 'app-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss'],
})
export class ContentEditorComponent implements OnInit, OnChanges {
  // incoming preselection for new or updated entries
  @Input() set selectedContent(
    selectedContent: IGdprRelevantElement | undefined
  ) {
    this.content = selectedContent;
    // check, which display mode is preselected
    this.textActive =
      this.content?.display_mode === ContentEditorModeEnum.text ||
      this.content?.display_mode === undefined;
    this.checkValidContentToSave();
  }
  // reason for editing
  @Input() editReason!: EditGdprActionEnum;
  // already existing content
  @Input() set incommingText(text: any) {
    this.existingText = text;
    this.text = text;
  }
  // set the already existing filename to the input field
  @Input() set existingFileName(name: string) {
    this.fileName = name;
  }
  // Trigger when an element is to be updated or newly created
  @Output() changeContent = new EventEmitter<{
    reason: EditGdprActionEnum;
    element: IGdprRelevantElement;
    content: string | File | undefined;
  }>();
  // maximum allowed file size in mb
  readonly maxFileSize: number = 15;
  // name of the file using in the input field of the pdf upload
  fileName?: string = '';
  // uploaded fiele
  file?: File;
  // existing text for the text editor
  existingText?: string;
  // new text from the text editor
  text?: string;
  // check the file size
  fileToBig: boolean = false;
  // check the file format
  fileWrongFormat: boolean = false;
  // check if the save button is disabled
  disableSaveButton: boolean = false;
  // display mode, language and default language
  content?: IGdprRelevantElement;
  // is the display mode 'text' selected
  textActive: boolean = true;
  // configuration for the text editor
  textEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '100%',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: 'p',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'insertUnorderedList',
        'insertOrderedList',
        'insertUnorderedList',
        'insertOrderedList',
        'indent',
        'outdent',
        'fontName',
        'heading',
        'superscript',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: this.translate.instant('edit-gdpr.text-editor.first-headline'),
        class: 'first-headline',
        tag: 'h1',
      },
      {
        name: this.translate.instant('edit-gdpr.text-editor.second-headline'),
        class: 'second-headline',
        tag: 'h3',
      },
      {
        name: this.translate.instant('edit-gdpr.text-editor.paragraph'),
        class: 'paragraph',
        tag: 'p',
      },
    ],
  };
  isContentEditorDisabled = true;

  /**
   * Creates an instance of the ContentEditorComponent
   *
   * @param mapService map functions
   * @param translate transation handling
   * @param gdprService handles the gdpr information
   */
  constructor(
    public mapService: MapService,
    private translate: TranslateService,
    public gdprService: GdprService
  ) {}

  /**
   * initial check if the content is valid
   */
  ngOnInit(): void {
    this.checkValidContentToSave();
  }

  /**
   * check incoming changes to put existing filenames in the input field of the upload or to remove it
   *
   * @param changes hashtable of changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.existingFileName?.currentValue) {
      this.fileName = changes.existingFileName?.currentValue;
    } else if (changes.editReason?.currentValue === 'add') {
      this.fileName = '';
      this.file = undefined;
      this.checkValidContentToSave();
    }
  }

  /**
   * checked if the display mode text is active, if not pdf is active
   *
   * @param mode 'PDF' or 'TEXT'
   */
  public isTextActive(mode: string): void {
    this.textActive = mode === ContentEditorModeEnum.text;
    this.checkValidContentToSave();
  }

  /**
   * Trigger changes when an gdpr relevant element should be updated or newly created
   *
   * @param changeReason reason for the change (update, add, discard)
   */
  public updateChanges(changeReason: string): void {
    if (this.content) {
      const reason =
        changeReason === EditGdprActionEnum.discardChanges
          ? EditGdprActionEnum.discardChanges
          : EditGdprActionEnum.save;

      const element: IGdprRelevantElement = {
        language: this.content.language,
        default_language: this.content.default_language,
        display_mode: this.textActive
          ? ContentEditorModeEnum.text
          : ContentEditorModeEnum.pdf,
        file_status:""

      };

      const content = this.textActive ? this.text : this.file;
      this.changeContent.emit({ reason, element, content });
    }
  }

  /**
   * get and validate file after selection
   *
   * @param event upload event
   */
  onFileSelected(event: any): void {
    this.file = event.target.files[0];
    if (this.file) {
      // set the filename in the input field of the file upload
      this.fileName = this.file.name;
      // get the file size in bytes and calculate in mb
      let sizeInMb: number = Math.round(this.file.size / 1024) / 1000;
      this.fileToBig = sizeInMb > this.maxFileSize;
      this.fileWrongFormat = this.file.type !== 'application/pdf';
      this.checkValidContentToSave();
    }
  }

  /**
   * Check whether the file or text is valid and can therefore be saved.
   */
  checkValidContentToSave(): void {
    this.disableSaveButton = this.textActive
      ? this.existingText === this.text || this.text === ''
      : this.fileToBig || this.fileWrongFormat || this.file === undefined;
  }
}
